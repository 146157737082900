<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a @click="goBack" class="nav__btn nav__btn--prev">
				<i class="icon icon icon-close">{{t('10030')}}</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container container--fit" v-if="mmb0121 !== undefined">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub dormant">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								{{t('10211')}}
							</h2>
							<p class="inner__text">
								{{t('10212')}}
							</p>
						</div>
						<div class="inner__wrap">
							<div class="dormant__wrap">
								<ul class="dormant__list">
									<li>
									<span class="dormant__type">
										{{t('10213')}}
									</span>
										<span class="dormant__date">
										{{mmb0121.join_date}}
									</span>
									</li>
									<li>
									<span class="dormant__type">
										{{t('10214')}}
									</span>
										<span class="dormant__date">
										{{mmb0121.last_login_date}}
									</span>
									</li>
									<li>
									<span class="dormant__type">
										{{t('10215')}}
									</span>
										<span class="dormant__date red">
										{{mmb0121.sleep_date}}
									</span>
									</li>
								</ul>
								<p class="description">
									{{t('10216')}}
								</p>
								<div class="dormant__inner" v-if="auth === false">
									<h3 class="dormant__title">
										{{t('10221')}}
									</h3>
									<div class="dormant__auth">
										<label>
											<div class="radio">
												<input type="radio" class="rad blind" name="isDormant">
												<span class="radio__bg"></span>
												<span class="dormant__authtype">
													{{t('10222')}}
													<small>{{maskEmail(mmb0121.mem_id)}}</small>
												</span>
											</div>
										</label>
									</div>
									<div class="dormant__auth">
										<label>
											<div class="radio">
												<input type="radio" class="rad blind" name="isDormant">
												<span class="radio__bg"></span>
												<span class="dormant__authtype">
													{{t('10223')}}
													<small>({{mmb0121.hp_no}})</small>
												</span>
											</div>
										</label>
									</div>
								</div>
								<div class="dormant__inner" v-else>
									<h3 class="dormant__title">
										{{t('10124')}}
									</h3>
									<div class="dormant__auth">
										<div class="frms__item">
											<div class="frms__wrap" :class="{failed: isFailed}">
												<input type="text" pattern="[0-9]*" inputmode="numeric" class="ipt-frm" :placeholder="t('10779')" :value="verifyCode" @input="event => verifyCode = event.target.value"><!-- 재번역 체크 -->
												<div class="frms__btns">
													<!-- 남은 시간 -->
													<span class="frms__time eng">
														{{String(Math.trunc(phoneValidateTime / 60)).padStart(2, "0")}}:{{String(phoneValidateTime % 60).padStart(2, "0")}}
													</span>
													<button type="button" class="btn btn-medium btn-secondary active" @click="reSend">{{t('10125')}}</button>
												</div>
											</div>
											<div class="frms__msg" v-if="isFailed">
												{{t('10126')}}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="btns" v-if="auth === false">
						<button type="button" class="btn btn-confirm" @click="handleSubmit">인증번호 보내기</button><!-- 재번역 체크 -->
					</div>
					<div class="btns" v-else>
						<button type="button" class="btn btn-confirm" @click="verify">{{t('10218')}}</button>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
	<ErrorAlert v-if="openErrorAlert" :type="alert_msg" :handleClose="handleCloseErrorAlert" :handleClick="handleClickAlert"/>
	<TheNavigation></TheNavigation>
</template>

<script>
import { computed, ref } from '@vue/reactivity';
import { useRouter } from 'vue-router'
import { useStore } from 'vuex';
import { onMounted } from '@vue/runtime-core';

import ErrorAlert from "@/components/layers/Message_alert";
import usePhoneValidate from '@/composables/usePhoneValidate';
import { useI18n } from 'vue-i18n' //번역필수 모듈
import i18n from "@/i18n"; 

export default {
	components: {
		ErrorAlert,
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const openErrorAlert = ref(false)
		const mmb0121 = computed(() => store.state.mmb01.mmb0121 !== null && store.state.mmb01.mmb0121.length > 0 ? store.state.mmb01.mmb0121[0] : undefined);

		const auth = ref(false);
		const isVerified = ref(false);
		const isFailed = ref(false); 
		const verifyCode = ref('');
		const maskEmail = (email) => {
			let split = email.split('@')
			return split[0].slice(0, -3) + "xxx" + "@" + split[1]
		}
		const {
			phoneValidateTime,
			onClickSendPhoneNumber,
			serverPhoneValidateNumber
		} = usePhoneValidate("04");

		const handleSubmit = () => {
			auth.value = true;
			onClickSendPhoneNumber(mmb0121.value.hp_no);
		}

		onMounted(() => {
			if(mmb0121.value === undefined) {
				router.go(-1);
			}
		})

		const verify = () => {
			//console.log(verifyCode.value, typeof verifyCode.value);
			//console.log(serverPhoneValidateNumber.value, typeof serverPhoneValidateNumber.value);
			if(serverPhoneValidateNumber.value !== verifyCode.value.toString()) {
				//alert("인증 번호가 일치하지 않습니다. 다시 확인해주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "인증 번호가 일치하지 않습니다. 다시 확인해주세요."});
				openErrorAlert.value = true;
				isFailed.value = true;
				return;
			}
			if(phoneValidateTime.value === 0){
				//alert("입력시간을 초과하였습니다. 재전송 버튼을 눌러주세요.");
				store.commit("app/SET_RESMESSAGE", {res_message: null});
				store.commit("app/SET_RESMESSAGE", {res_message: "입력시간을 초과하였습니다. 재전송 버튼을 눌러주세요."});
				openErrorAlert.value = true;
				return;
			}
			isVerified.value = true;
			router.push("/member/dormant/complete");
		}
		const { t }= useI18n() //번역필수 모듈

		return {
			goBack() {
				router.go(-1);
			},
			mmb0121,
			handleSubmit,
			auth,
			reSend: () => {
				if(phoneValidateTime.value > 0){
					//alert("인증번호를 재발송 하였습니다. 인증번호가 오지 않는 경우 입력하신 정보를 확인해주세요.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "인증번호를 재발송 하였습니다. 인증번호가 오지 않는 경우 입력하신 정보를 확인해주세요."});
					openErrorAlert.value = true;
				}else {
					//alert("인증번호를 재발송 하였습니다.");
					store.commit("app/SET_RESMESSAGE", {res_message: null});
					store.commit("app/SET_RESMESSAGE", {res_message: "인증번호를 재발송 하였습니다."});
					openErrorAlert.value = true;
				}
				onClickSendPhoneNumber(mmb0121.hp_no);
			},
			verify,
			isVerified,
			isFailed,
			verifyCode,
			maskEmail,
			openErrorAlert,
			handleCloseErrorAlert: () => {
				openErrorAlert.value = false;
			},
			phoneValidateTime,
			t,
			i18n,
		}
	},
}
</script>